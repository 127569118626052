/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

$mainfont: "proxima-nova";
$bg-failure: #eb4d4b;
$bg-success: #6ab04c;
$bg-pending: rgba(189,100,0,0.6);

// Theme Colours
$themecolor1: #6a327c; // header
$themeaccentcolor1: #814495;
$themecolor2: #f5f5f5; // background
$themecolor3: #dfe4ea; // pagetitle
$themecolor4: #ffffff; // table-container
$themecolor5: #535c68; // menu

$menudividerlight: #743a87;
$menudividerdark: #572767;
$submenudividerlight: #8f51a4;
$submenudividerdark: #6b377c;

$buttoncolor1: #eb4d4b;
$buttonaccentcolor1: #ff7979;
$buttoncolor2: #f1f2f6;

$titlecolor1: #000000;
$titlecolor2: #227093;

// dark theme
// $themecolor1: #aaa69d;
// $themecolor2: #f7f1e3;



// Global styles
html {height: 100%;}
body {margin: 0px !important; }
h1 {font-size: 20px;font-weight: 700;font-family: $mainfont, sans-serif;color: $titlecolor1;}

// Body and Container
.mat-drawer-container {background: $themecolor2;background-size: cover;}
.container {height: 100vh;}

// Miscellaneous
.toolbarspacer {flex: 1 1 auto;}
.clear {clear: both;}

// Button Styles
.redbtn {background: $buttoncolor1;color: #fff;}
.redbtn:hover {color: #fff;background: $buttonaccentcolor1;}
.greybtn {background: $buttoncolor2;color: $titlecolor1;}
.greybtn:hover {color: $titlecolor1;}
.filterbtn {background: transparent !important;color: $titlecolor1;}

// Form Styles
.mat-form-field {font-family: $mainfont; }

// Remove browser unwanted styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $themecolor2 inset !important;
}


.vertical-layout, div.defaultLightbox, div.login, div.login > form  { display: flex; flex: 1; flex-direction: column; }

.horizontal-layout, div.main { display: flex; flex: 1; flex-direction: row; }


// Main Header
// ===========
.header {

    & { position: fixed; background: #fff; z-index: 99; }

    .mat-toolbar-row {color: #fff;}
    .closebtn {display: block;background: $themeaccentcolor1;color: #fff;font-size: 24px;width: 20px;text-align: center;margin: 10px 60px 10px -16px;height: 30px;padding-top: 8px;transition: width 1s ease;}
    .menubtn {display: block;background: $themeaccentcolor1;color: #fff;font-size: 28px;width: 80px;text-align: center;margin: 0 20px 0 -16px;height: 48px;padding-top: 16px;transition: width 1s ease;}

    //.menu {display: block;color: #fff;font-size: 0.85em;text-transform: uppercase;font-family: $mainfont;padding: 16px 30px 9px;font-weight: 800;text-decoration: none;border-bottom: 5px solid $themecolor1;}
    .active {border-bottom: 7px solid #fff;}
    .logo img {width: 200px; }
    a.logo { line-height: 0px; }
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {background-color: $themecolor1 !important;}
.mat-ink-bar {height: 4px;}
.mat-tab-label-content {text-transform: uppercase;font-family: $mainfont;}
.mat-tab-labels {background: #fff;}



.aside {
    & {font-family: $mainfont;width: 90%;background: #ecf0f1;padding: 0;}

    .header { width: 100%; padding: 15px 2%; margin: 0; background: #FFF;box-shadow: rgba(0,0,0,0.2); text-transform: uppercase; }
    .content { margin: 20px; text-align: justify; }
    .actionBtn { display: flex; justify-content: flex-end; margin: 20px; }

}

.navbar {

    & {display: flex;flex-direction: column;width: 340px;background: #ced6e0; }

    a {
        & { display: block; width: 80%; margin: 0 auto; color: #FFF;
            text-decoration: none; font-size: 1.2em; padding: 5px 10px; border-bottom: 1px solid #3d8fb6;
            border-top: 1px solid #277499; font-family: $mainfont; }

        &:first-of-type { border-top: none; }

        &:last-of-type { border-bottom: none; }

        &:hover { color: #ecf0f1; }
    }
}

/* -- Menu Main -- */
/* -- ========= -- */
.mainmenu {

    & {width: 300px;background: $themecolor1;color: #fff;}

    .menulogo {height: 32px;margin: 6px 0 2px -20px;}

    .container {height: 100%;}
    nav h2 {color: #fff;font-size: 1.5em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 800;}
    nav h3 {color: #fff;font-size: 1.2em;letter-spacing: 0.1em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 600;}
    nav a {display: block;width: 90%;margin: 0 auto;color: #fff;text-decoration: none;font-size: 1.1em;font-weight: 300;padding: 15px 5px;background: none;border-bottom: 1px solid $menudividerlight;border-top: 1px solid $menudividerdark;}
    nav .profile {background: $themeaccentcolor1;padding: 20px;text-align: center;font-size: 1.4em;display: flex;align-items: center;}
    nav .profile .profilepic {flex: 0 0 60px;height: 47px;border-radius: 50%;background: $themecolor1;padding-top: 13px;}
    nav .profile .profiledetails {flex: 1 1 auto;font-size: 14px;text-align: left;line-height: 16px;margin-left: 10px;}
    nav .profile .profiledetails span {font-size: 12px;}
    nav .submenu {padding: 5px 5px 5px 20px;font-size: 0.9em;border-top: 1px solid $submenudividerlight;border-bottom: 1px solid $submenudividerdark;}
    nav .sub {background: $themeaccentcolor1;}
    nav a:first-of-type {border-top: none;}
    nav a:last-of-type {border-bottom: none;}
    nav a:hover {color: #ecf0f1;}
    nav a i {display: block;float: left;margin-top: -4px;margin-right: 20px;font-size: 28px;opacity: 0.3;}
    .logo { padding: 15px 10px; width: 90%; border: none; line-height: 0; }
    .logo img { width: 150px; }
}






// main h1 {margin: 10px 0 10px 0;font-size: 2.5em;font-weight: 800;text-transform: uppercase;color: #3c6382;}

// Main container and their styles
// -------------------------------
main {

    // Main toolbar
    .pagetitle {background: transparent !important;width: 100%;}
    .mat-toolbar {background: transparent !important;}
    .mat-toolbar-row h1 {margin: 10px 0 10px 0;font-size: 1.5em;font-weight: 800;text-transform: uppercase;color: $titlecolor1;font-family: $mainfont;}
    .mat-toolbar-row button {font-weight: 800;font-family: $mainfont;text-transform: uppercase;margin-right: 5px;}

    .table-container {display: flex;flex-direction: column;min-width: 300px;background: $themecolor4;border-top: 2px solid $themecolor1;}

    // Mat Table
    // ---------
    .mat-table {font-family: $mainfont;overflow: auto;max-height: 80vh;}
    .mat-header-row {position: sticky;top: 0;background-color: #fff;z-index: 1000;}
    .mat-header-cell {font-size: 1em;color: $themecolor1;font-family: $mainfont;text-transform: uppercase;font-weight: 800;}
    .mat-menu-item {font-family: $mainfont;letter-spacing: 0;font-size: 12px;padding: 0 8px !important;height: auto !important;line-height: 36px !important;margin-bottom: 0;}

    .draft {background: #e55039;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .running {background: #78e08f;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .closed {background: none;color: #78e08f;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}

}

.mat-column-responses {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-status {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-tools {flex: 0 0 80px !important;text-align: right;}



app-lightbox { display: flex; justify-content: center; position: fixed;  width: 100%; height: 100%;
               background: rgba(0,0,0,0.5);  z-index: 10000; font-family: $mainfont; }

.lightbox {
    & { position: fixed; text-align: center; padding: 0px; background: #FFF;
        z-index: 10001; border-radius: 9px; min-height: 100px; top: 64px; font-family: $mainfont; }

    h1 { color: #FFF; background-color: #16577f; font-size: 1.5em; margin: 0px;
         padding: 20px 0px; border-top-right-radius: 7px; border-top-left-radius: 7px; }
}

.defaultLightbox > div {
    padding: 10px;
    font-family: $mainfont;
    &:last-of-type { display: flex;  justify-content: flex-end; }
    > p { text-align: justify; }
}

// Login page
app-login { display: flex; justify-content: center;}

.login {

    & {min-width: 30vw;margin-top: 100px;font-family: $mainfont;background: $themecolor4;padding: 30px;}
    .loginheader {margin: -30px -30px 0 -30px;background: $themecolor1;padding: 20px;text-align: center;}
    .loginheader img {margin: 0 auto;width: 200px;}
    div.actionBtn { display: flex; justify-content: flex-end; }
    .mat-form-field {background: $themecolor4;}
    form {
        input[type="text"] { height: 16px; padding: 0px; border: 0; background: none; border-radius: 0px; }
        input[type="email"] { height: 16px; padding: 0px; border: 0; background: none; border-radius: 0px; }
        input[type="password"] { height: 16px; padding: 0px; border: 0; background: none; border-radius: 0px; }
        input[type="password"]:focus { border: none; outline: none; }
    }

}

// Snackbar container
// ----------------
.mat-snack-bar-container {margin: 20px !important;}
.mat-snack-bar-container-failure {background-color: $bg-failure;}
.mat-snack-bar-container-success {background-color: $bg-success;}
.mat-snack-bar-container-pending {background-color: $bg-pending;color: #fff;}


// ============================= purplepanda Quiz ============================

// =================== global css ===================

// fonts
$sourcesans           : 'Source Sans Pro', sans-serif;
$opensans             : 'Open Sans', sans-serif;

// colors
$quiz_main_color      : #121640;

// buttons colors
$quiz_button_color_1  :#cb88ff;

// buttons
.quiz-button { font-size: 16px; font-weight: 600; height: 50px; border-radius: 50px !important; }
.quiz-button.loading .btn-text { display: none; }
.quiz-button.loading { min-width: 70px !important; width: 70px; border-radius: 100%; height: 70px !important; }
.quiz-button .submit-loader { width: 50px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
.voiletbtn { background-color:$quiz_button_color_1; color:#fff !important;  }

// classes
.frame { max-width: 1440px; margin: 0px 70px; width: calc(100% - 140px); }
body { font-family:  $opensans; }
.trans-out { visibility:hidden !important; opacity:0 !important; }

//scrollbar
::-webkit-scrollbar { height: 6px; width: 6px; }
::-webkit-scrollbar-thumb { background-color: #6e6e6e; outline: #444 solid 1px; }
::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

// mat classes

// tooltip
.mat-tooltip { color: $quiz_main_color !important; background-color: transparent !important; font-weight:700 !important; font-size:12px !important; font-family:$opensans; }

// mat dropdown
.mat-select-panel-wrap .mat-select-panel { background-color: $quiz_main_color; }
.mat-select-panel-wrap .mat-select-panel .mat-option,.mat-select-panel-wrap .mat-select-panel .mat-option.mat-selected:not(.mat-option-disabled) { color:#fff; }

// animation
.fade-out {
  .aos-animate { opacity: 0 !important; transform: translate3d(0,-100px,0) !important; }
}
// =================== quiz page(global) ===================
.quiz-wrapper {

  // quiz block
  .quiz-block { min-height: 100vh; flex-direction: column; justify-content: center; display:flex; }
  // .quiz-block { min-height: 100vh; transition: opacity 1.5s ease-in, filter 0.9s ease-out; display: flex; flex-direction: column; justify-content: center; display:none; filter: blur(5px); opacity:0; }
  // .filter-blur { filter:blur(0px) !important; opacity:1 !important; }
  // .quiz-block.visible { display:flex; }

  // .quiz-block { flex-direction: column; justify-content: center; display:flex; visibility: hidden; opacity:0; height:0; overflow: hidden; }
  // .quiz-block.visible { min-height: 100vh; visibility: visible; opacity:1; height:auto; overflow: visible; }
}

// =================== quiz start ===================
app-quiz-start { position: relative; z-index: 100; }
.quiz-start-page {
  & { background-color: $quiz_main_color; height: calc(100vh - 60px); padding: 30px; display: flex; justify-content: center; align-items: center; flex-direction: column; }
  .quiz-start-page-title { color: #fff; font-size: 22px; margin-bottom: 24px; text-align: center; }
  .quiz-start-button { font-size: 40px; height: 80px; text-transform: uppercase; font-weight: 700; font-family:$sourcesans; min-width: 230px;transition: all 1s ease;}
  .quiz-start-button.loading { min-width: 70px !important; width: 70px; border-radius: 100%; height: 70px !important; }
}

// =================== quiz form & stepper ===================
.quiz-form-wrapper {

  // quiz stepper
  .mat-stepper-horizontal {
    & { position: fixed; top: 0px; left: 245px; right: 30px; z-index: 99; padding-top: 15px; font-family: $opensans; }
    .mat-horizontal-content-container { padding: 0px !important; }
    .mat-horizontal-stepper-header { pointer-events: none; padding: 0 0px !important; width: 25px; height:auto !important; overflow: visible; }
    .mat-horizontal-stepper-header:last-child, .mat-stepper-horizontal-line:last-of-type { display: none; }
    .mat-step-header .mat-step-icon { background-color: $quiz_main_color;}
    .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done { background-color: #fff; border: 4px solid $quiz_main_color; height: 18px; width: 18px; }
    .mat-stepper-click { position: absolute; left: 50%; width:30px; top: 50%; height: 30px; background-color: transparent !important; transform: translate(-50%, -50%); }
    .tool-tip { position: absolute; left: 50%; top: 30px; transform: translate(-50%, 0%); visibility:hidden; opacity:0; transition:0.4; }
    .mat-horizontal-stepper-header[tabindex="0"] .tool-tip, .mat-horizontal-stepper-header:hover .tool-tip { visibility:visible; opacity:1; }
    .mat-step-icon { height: 20px; width: 20px; }
    .mat-horizontal-stepper-header::after, .mat-horizontal-stepper-header::before, .mat-stepper-horizontal-line { border-top-color: $quiz_main_color; border-top-width: 5px !important; margin: 0 -10px !important; }
    .mat-stepper-horizontal-line { min-width: auto; }
    .mat-horizontal-stepper-header-container { flex-wrap: wrap; height: 30px; }
    .mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover { background-color: transparent; }
    // .mat-step-icon .mat-icon { color: $quiz_main_color; font-size: 18px; height: 25px; width: 25px; font-weight: 600; display: flex; align-items: center; justify-content: center; }
    .mat-step-icon .mat-icon { display:none; }
    .mat-step-icon-content span { display: none; }

    &.last-edited-step-1 mat-step-header:nth-of-type(1),
    &.last-edited-step-1 mat-step-header:nth-of-type(2),

    &.last-edited-step-2 mat-step-header:nth-of-type(1),
    &.last-edited-step-2 mat-step-header:nth-of-type(2),
    &.last-edited-step-2 mat-step-header:nth-of-type(3),

    &.last-edited-step-3 mat-step-header:nth-of-type(1),
    &.last-edited-step-3 mat-step-header:nth-of-type(2),
    &.last-edited-step-3 mat-step-header:nth-of-type(3),
    &.last-edited-step-3 mat-step-header:nth-of-type(4),

    &.last-edited-step-4 mat-step-header:nth-of-type(1),
    &.last-edited-step-4 mat-step-header:nth-of-type(2),
    &.last-edited-step-4 mat-step-header:nth-of-type(3),
    &.last-edited-step-4 mat-step-header:nth-of-type(4),
    &.last-edited-step-4 mat-step-header:nth-of-type(5),

    &.last-edited-step-5 mat-step-header:nth-of-type(1),
    &.last-edited-step-5 mat-step-header:nth-of-type(2),
    &.last-edited-step-5 mat-step-header:nth-of-type(3),
    &.last-edited-step-5 mat-step-header:nth-of-type(4),
    &.last-edited-step-5 mat-step-header:nth-of-type(5),
    &.last-edited-step-5 mat-step-header:nth-of-type(6),

    &.last-edited-step-6 mat-step-header:nth-of-type(1),
    &.last-edited-step-6 mat-step-header:nth-of-type(2),
    &.last-edited-step-6 mat-step-header:nth-of-type(3),
    &.last-edited-step-6 mat-step-header:nth-of-type(4),
    &.last-edited-step-6 mat-step-header:nth-of-type(5),
    &.last-edited-step-6 mat-step-header:nth-of-type(6),
    &.last-edited-step-6 mat-step-header:nth-of-type(7),

    &.last-edited-step-7 mat-step-header:nth-of-type(1),
    &.last-edited-step-7 mat-step-header:nth-of-type(2),
    &.last-edited-step-7 mat-step-header:nth-of-type(3),
    &.last-edited-step-7 mat-step-header:nth-of-type(4),
    &.last-edited-step-7 mat-step-header:nth-of-type(5),
    &.last-edited-step-7 mat-step-header:nth-of-type(6),
    &.last-edited-step-7 mat-step-header:nth-of-type(7),
    &.last-edited-step-7 mat-step-header:nth-of-type(8),

    &.last-edited-step-8 mat-step-header:nth-of-type(1),
    &.last-edited-step-8 mat-step-header:nth-of-type(2),
    &.last-edited-step-8 mat-step-header:nth-of-type(3),
    &.last-edited-step-8 mat-step-header:nth-of-type(4),
    &.last-edited-step-8 mat-step-header:nth-of-type(5),
    &.last-edited-step-8 mat-step-header:nth-of-type(6),
    &.last-edited-step-8 mat-step-header:nth-of-type(7),
    &.last-edited-step-8 mat-step-header:nth-of-type(8),
    &.last-edited-step-8 mat-step-header:nth-of-type(9),

    &.last-edited-step-9 mat-step-header:nth-of-type(1),
    &.last-edited-step-9 mat-step-header:nth-of-type(2),
    &.last-edited-step-9 mat-step-header:nth-of-type(3),
    &.last-edited-step-9 mat-step-header:nth-of-type(4),
    &.last-edited-step-9 mat-step-header:nth-of-type(5),
    &.last-edited-step-9 mat-step-header:nth-of-type(6),
    &.last-edited-step-9 mat-step-header:nth-of-type(7),
    &.last-edited-step-9 mat-step-header:nth-of-type(8),
    &.last-edited-step-9 mat-step-header:nth-of-type(9),
    &.last-edited-step-9 mat-step-header:nth-of-type(10),

    &.last-edited-step-10 mat-step-header:nth-of-type(1),
    &.last-edited-step-10 mat-step-header:nth-of-type(2),
    &.last-edited-step-10 mat-step-header:nth-of-type(3),
    &.last-edited-step-10 mat-step-header:nth-of-type(4),
    &.last-edited-step-10 mat-step-header:nth-of-type(5),
    &.last-edited-step-10 mat-step-header:nth-of-type(6),
    &.last-edited-step-10 mat-step-header:nth-of-type(7),
    &.last-edited-step-10 mat-step-header:nth-of-type(8),
    &.last-edited-step-10 mat-step-header:nth-of-type(9),
    &.last-edited-step-10 mat-step-header:nth-of-type(10)
    {
      pointer-events: auto !important;
    }

  }

  .step-fade-bg { position: fixed; top: 60px; width: 100%; height: 100px; z-index: 98; }
  .step-fade-bg img { width: 100%; height: 100px; }
  .step-fade-bg-bottom { display: none; position: fixed; bottom: 38px; left: 0; width: 100%; height: 80px; z-index: 9; }
  .step-fade-bg-bottom img { width: 100%; height: 80px; }

  // btns
  .quiz-navigation-btns {
    .left-btn, .right-btn { position: fixed; z-index: 10; bottom: 40px; background-color: $quiz_main_color; border-radius: 100%; color: #fff; width: 45px; height: 45px; display: flex; align-items: center; justify-content: center; font-weight: 700; font-size: 36px; cursor: pointer; }
    .left-btn { left: 40px; }
    .right-btn { right: 40px; }
    .right-btn.inactive { background-color: grey; }
  }

  // quiz mobile tool tip
  .quiz-mobile-tool-tip { display:none; position: fixed; z-index: 10; bottom: 50px; left: 50%; transform: translate(-50%, 0px); font-size: 14px; font-weight: 500; }

  // quiz form
  .quiz-form {
    .quiz-block {
      & {text-align: center; }

      // heading
      .main-heading { font-size: 36px; font-weight: 700; color:$quiz_main_color; font-family:$sourcesans; line-height: 36px;  }
      .secondary-heading { font-size: 23px; font-weight: 500; color:$quiz_main_color; font-family:$sourcesans; line-height: 36px;  }
      .secondary-heading strong { font-weight: 700; text-decoration: underline; }
      .main-heading-small { font-size: 18px; }
      // radio layout
      .quiz-radio-layout {
        & { max-width: 600px; margin: 0 auto; width: 100%; }
        &.quiz-radio-layout-800 { max-width: 800px; margin: 0 auto; width: 100%; }
        &.quiz-radio-layout-700 { max-width: 700px; margin: 0 auto; width: 100%; }
        mat-radio-button { background-color: $quiz_main_color; color: #fff; border-radius: 50px; display: block; font-size: 24px; margin: 20px auto; font-family:$sourcesans; }
        .mat-radio-label { margin-bottom: 0px; }
        .mat-radio-container { display: none; }
        .mat-radio-label-content { width: 100%; padding: 0px; min-height: 36px; display: flex; align-items: center; justify-content: center; white-space: break-spaces; padding: 6px 15px; }
        .mat-radio-checked { background-color:$quiz_button_color_1; }
       }

      // input layout
      .quiz-input-layout {
        input { background-color: $quiz_main_color; color: #fff; border-radius: 50px; display: block; font-size: 22px; max-width: 600px; margin: 20px auto; border: none; text-align: center; outline: none; height: 50px; width: 100%; font-family:$sourcesans; }
        input::placeholder { color:#fff; opacity: 0.3; }
        .input-btn { max-width: 600px; width:100%; height: 50px; font-size: 22px; font-family:$sourcesans; transition:0.4s; }
        .input-btn.loading { min-width: 60px !important; max-width: 60px !important; height: 60px !important; }
      }

      // checkbox layout
      .quiz-checkbox-layout {
        mat-checkbox { color: #fff; display: block; font-size: 22px; max-width: 600px; margin: 30px auto; text-align: center; outline: none; width: 100%; font-family:$sourcesans; }
        .checkbox-btn { max-width: 600px; width:100%; height: 50px; font-size: 22px; font-family:$sourcesans; }
        .mat-checkbox-layout { margin-bottom: 0px; }
        .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background { background-color: #fff; border-radius: 100%; }
        .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element { background: #fff; }
        .mat-checkbox-frame { border-radius: 100%; border-color: #fff; }
        .mat-checkbox-checkmark-path { stroke: $quiz_main_color !important; }
        .mat-checkbox-layout { width: calc(100% - 15px); background-color: $quiz_main_color; border-radius: 50px; min-height: 28px; border: 3px solid #fff; transition: 0.4s; padding: 6px 15px 6px 0px; }
        .mat-checkbox-checked .mat-checkbox-layout{ border-color: $quiz_button_color_1; background: $quiz_button_color_1; }
        .mat-checkbox-inner-container { margin-left: 10px; }
        .mat-checkbox-label { height: 100%; display: flex; align-items: center; }
      }

      // mat dropdown
      .quiz-select-layout {
        mat-form-field { max-width: 600px; width: 100%; margin: 20px auto; background-color: $quiz_main_color; border-radius: 50px; height: 50px; }
        .mat-form-field-wrapper { padding-bottom: 0px; }
        .mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end { border-width:0px; }
        .mat-select-arrow { color: #fff; }
        .mat-select-placeholder, .mat-select-value { color: #fff; font-size: 22px; font-family:$sourcesans; }
        .mat-form-field-infix { padding: 12px 5px; border-top: 0px; }
        .mat-form-field-appearance-outline .mat-select-arrow-wrapper { transform: translateY(0%); }
      }
    }

  }

}
