@media only screen and (max-width: 1199px) {
  .quiz-form-wrapper .quiz-form .quiz-block .main-heading { font-size: 32px; line-height: 32px; }
  .quiz-start-page .quiz-start-button { font-size: 32px; height: 70px; }
}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 991px) {
  .quiz-start-page .quiz-start-button { font-size: 28px; height: 60px; min-width: 200px !important; }
  .quiz-start-page .quiz-start-page-title { font-size: 20px; margin-bottom: 15px; }
  .header .logo img { width: 175px; }
  .quiz-form-wrapper .mat-stepper-horizontal .tool-tip { font-size: 12px; }
  .quiz-form-wrapper .mat-stepper-horizontal .mat-step-icon { height: 15px; width: 15px; }
  .quiz-form-wrapper .mat-stepper-horizontal .mat-step-header .mat-step-icon-selected, .quiz-form-wrapper .mat-stepper-horizontal .mat-step-header .mat-step-icon-state-done { border-width: 3px; height: 14px; width: 14px; }
  .quiz-form-wrapper .quiz-navigation-btns .left-btn, .quiz-form-wrapper .quiz-navigation-btns .right-btn { width: 35px; height: 35px; font-size: 26px; bottom: 20px; }
  .quiz-form-wrapper .quiz-navigation-btns .left-btn { left: 20px; }
  .quiz-form-wrapper .quiz-navigation-btns .right-btn { right: 20px; }
  .quiz-form-wrapper .quiz-form .quiz-block .main-heading { font-size: 26px; line-height: 26px; }
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-radio-layout mat-radio-button,
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-select-layout .mat-select-placeholder, .quiz-form-wrapper .quiz-form .quiz-block .quiz-select-layout .mat-select-value,
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-checkbox-layout mat-checkbox,
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-checkbox-layout .checkbox-btn,
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-input-layout input,
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-input-layout .input-btn { font-size: 20px; }
}

@media only screen and (max-width: 767px) {
  .quiz-start-page .quiz-start-page-title { font-size: 18px; }
  .quiz-start-page .quiz-start-button { font-size: 24px; height: 55px; }
  .quiz-form-wrapper .mat-stepper-horizontal { top: auto; left: 0px; right: 0px; padding: 5px 0px 5px 5px; bottom: 0px; }
  .quiz-form-wrapper .step-fade-bg { top: 55px; height: 80px; }
  .quiz-form-wrapper .step-fade-bg img { height: 80px; }
  .quiz-form-wrapper .step-fade-bg-bottom { display: block; height: 60px; }
  .quiz-form-wrapper .step-fade-bg-bottom img { height: 60px; }
  .quiz-form-wrapper .quiz-navigation-btns .left-btn, .quiz-form-wrapper .quiz-navigation-btns .right-btn { bottom: 45px; }
  .quiz-form-wrapper .mat-stepper-horizontal .tool-tip { display: none; }
  .quiz-mobile-tool-tip { display:block !important; }
}

@media only screen and (max-width: 600px) {
  .quiz-start-page .quiz-start-page-title { font-size: 16px; }
  .quiz-start-page .quiz-start-button { font-size: 22px; height: 50px; min-width: 175px !important; }
  .quiz-form-wrapper .quiz-form .quiz-block .main-heading { font-size: 24px; line-height: 24px; }
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-radio-layout mat-radio-button,
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-select-layout .mat-select-placeholder, .quiz-form-wrapper .quiz-form .quiz-block .quiz-select-layout .mat-select-value,
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-checkbox-layout mat-checkbox,
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-checkbox-layout .checkbox-btn,
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-input-layout input,
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-input-layout .input-btn { font-size: 18px; }
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-radio-layout mat-radio-button,
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-select-layout mat-form-field,
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-checkbox-layout mat-checkbox,
  .quiz-form-wrapper .quiz-form .quiz-block .quiz-input-layout input { margin: 20px auto; }
  .frame { margin: 0px 20px; width: calc(100% - 40px); }
}

@media only screen and (max-width: 480px) {

}

@media only screen and (max-width: 375px) {

}

@media only screen and (max-width: 320px) {

}
